import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const fetchFrontendInfo = createAsyncThunk(
    "info-fetch-d",
    async (args, { rejectWithValue }) => {
        try {
            // if (localStorage.getItem("frontendInfo")) {
            //     console.log("Fetching frontend info from local storage...  ")
            //     return JSON.parse(localStorage.getItem("frontendInfo"));
            // }

            console.log("Fetching frontend info...  ");
            let api = process.env.REACT_APP_HOSTED_BANKING;
            let route = "/config";

            let hostname = window.location.hostname;
            // hostname = "www.frenetictrade.com";
            console.log(hostname)

            if (hostname.split(".").length > 2) {
                console.log("Subdomain detected")
                hostname = hostname.split(".")[1] + "." + hostname.split(".")[2];
            }

            let tenant;
            let soft_url = "https://sentinel.solidhash.io/peripheral-license/license/domain/";
            let url = soft_url + hostname;
            let { data: apiData } = await axios.get(url);

            console.log(apiData)

            let tenant_id = apiData.tenant_id;
            let { data } = await axios.get(`${api}${route}`, {
                headers: {
                    "Authorization": "Bearer " + Cookies.get("token"),
                    "x-tenant-id": tenant_id
                }
            });


            localStorage.setItem("tenant_id", tenant_id);

            let fullData = {
                ...data,
                tenant_id: tenant_id
            }

            localStorage.setItem("frontendInfo", JSON.stringify(fullData));
            return fullData;


        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data);
        }

    }
);

const AccountSlice = createSlice({
    name: "frontendData",
    initialState: {
        data: {},
        isLoading: false,
        isFailed: false,
    },
    reducers: {},
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(fetchFrontendInfo.pending, (state, action) => {
            state.isLoading = true;
            state.isFailed = false;
        });
        builder.addCase(fetchFrontendInfo.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isFailed = false;
            state.data = action.payload;
        });
        builder.addCase(fetchFrontendInfo.rejected, (state, action) => {
            state.isLoading = false;
            state.isFailed = true;
        });
    },
});

export default AccountSlice.reducer;