import React from "react";
import formatNumber from "../../utils";
import styles from "./Info.module.css";

const Info = ({ investData }) => {
  console.log(investData);
  let amount = investData?.amount;
  console.log(amount);
  return (
    <div className={styles.wrapper}>
      <div className={styles.amountContainer}>
        <div>
          <h4 className={styles.amount}>
            {investData?.capital / Math.pow(10, 8)} <span className={styles.currency}>USD</span>
          </h4>
          <p className={styles.text}>Invested</p>
        </div>
        <div>
          <h4 className={styles.amount}>{investData.plan?.interest_rate} %</h4>
          <p className={styles.text}> {investData.interest_schedule}</p>
        </div>
        <div className={styles.totalReturned}>
          <h4 className={styles.amount}>
            {investData?.returns_earned ? (investData?.returns_earned) / 100000000 : "0"} <span className={styles.currency}>USD</span>
          </h4>
          <p className={styles.text}>Profit Earned</p>
        </div>
      </div>

      <div className={styles.boxWrapper}>
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Plan ID</p>
          <p className={styles.secondaryText}>{String(investData?.plan_id).toUpperCase()}</p>
        </div>{" "}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Daily Profit</p>
          <p className={styles.value}>{investData?.reward_per_cycle ? investData.reward_per_cycle / 10 ** 8 + " USD " + investData?.interest_schedule : ""}</p>
        </div>{" "}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Date</p>
          <p className={styles.secondaryText}>{new Date(investData?.created_at_timestamp * 1000).toLocaleString('en-GB')}</p>
        </div>{" "}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>End Date</p>
          <p className={styles.secondaryText}>{new Date(investData?.end_date_timestamp * 1000).toLocaleString('en-GB')}</p>
        </div>
        {/* <div className={styles.spaceBetween}>
          <p className={styles.text}>Payment Method</p>
          <p className={styles.value}>{investData.payment_method}</p>
        </div> */}




        <div className={styles.spaceBetween}>
          <p className={styles.text}> Reference ID</p>
          <p className={styles.value}>28713045</p>
        </div>{" "}
        {/* <div className={styles.spaceBetween}>
          <p className={styles.text}>Term end at</p>
          <p className={styles.secondaryText}>05 Mar, 2023 11:38 PM</p>
        </div> */}
        {/* <div className={styles.spaceBetween}>
          <p className={styles.text}>Adjust profit</p>
          <p className={styles.value}>0 / 7 times</p>
        </div>{" "} */}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Investment Status</p>
          <p className={styles.value}>{investData?.status}</p>
        </div>
      </div>
    </div>
  );
};

export default Info;
