import React, { useState } from "react";
import Input from "../Input/Input";
import styles from "./styles.module.css";
import axios from "axios";
import { toast } from "react-toastify";

const EnterEmail = ({ setShowEnterOtpComponent }) => {
  const [email, setEmail] = useState("");

  const submitHandler = async () => {

    let api = "https://broker-backend-service.bigrouting.io";
    let route = "/auth/forgot-password";

    let body = {
      email: email,
    };

    const { data, status } = await axios.post(api + route, body, {});

    if (status === 200) {
      toast.success("OTP sent to your email");
      console.log(data);
      localStorage.setItem("email", email);
    }

    setShowEnterOtpComponent(true);
  };
  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <h2 className={styles.title}>A Code will be sent</h2>
          <p className={styles.text}>
            {/* Sign in into your account using your email and passcode. */}
            Please Enter Your Registered Email
          </p>
        </div>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={styles.form}
        >
          <Input
            label="Email Address"
            type="email"
            name="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            type="submit"
            className={styles.button}
            onClick={submitHandler}
          >
            Next
          </button>
        </form>
      </div>

      <div className={styles.overlay}></div>
    </>
  );
};

export default EnterEmail;
