import React from "react";
import Hero from "../../Component/Home2/Hero/Hero";
import AboutCompany from "../../Component/Home2/CompanyPage/AboutCompany";
import OurValues from "../../Component/Home2/Sections/OurValues";
import ContactUs from "../../Component/Home2/Sections/ContactUs";
import Header2 from "../../Component/Home2/Header/Header";
import Footer2 from "../../Component/Home2/Footer/Footer";

const Company = ({ frontendInfo }) => {
	return (
		<div className="home2">
			<Header2 frontendInfo={frontendInfo} />
			<Hero
				videoLink="/home2/heros/companyVid.mp4"
				title="Company Overview"
				text="We move, create opportunities and protect money for customers and clients worldwide"
				height="590px"
			/>
			<AboutCompany frontendInfo={frontendInfo} />
			<OurValues frontendInfo={frontendInfo} />
			<ContactUs frontendInfo={frontendInfo} />
			<Footer2 frontendInfo={frontendInfo} />
		</div>
	);
};

export default Company;
