

import React, { useEffect } from 'react';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';

const Footer = ({ frontendInfo }) => {
  useEffect(() => {
    if (1 == 1) {
      // Create and append the Tidio script
      const script = document.createElement('script');
      script.src = frontendInfo?.tidio_id;
      script.async = true;

      // Append script to the document head
      document.head.appendChild(script);

      // Cleanup script on component unmount
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [frontendInfo]);

  const footerItems = [
    // { item: "FAQs", to: "faqs" },
    // { item: "Nutzungsbedingungen", to: "nutzungsbedingungen" },
    // { item: "Privacy Policy", to: "privacypolicy" },
  ];

  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footer}>
        <p className={styles.text}>
          {frontendInfo?.site_name + " "}2023. All Rights Reserved.
        </p>
        <div className={styles.footerItems}>
          {footerItems.map((el, i) => (
            <Link to={el.to} key={i} className={styles.link}>
              {el.item}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
