import React from "react";
import styles from "./Footer.module.css";

const Footer = ({ frontendInfo }) => {
	return (
		<section className={styles.container}>
			<div className={styles.content}>
				<div className={styles.head}>
					<div className={styles.logo}>
						<img src={frontendInfo?.logo} alt="quantfinances logo" />
						<p>Comprehensive financial advice and investment services that are tailored to meet your individual needs.</p>
					</div>
					<div className={styles.company}>
						<h4>Company</h4>
						<ul>
							<a href="#">
								<li>Home</li>
							</a>
							<a href="#">
								<li>About Us</li>
							</a>
							<a href="#">
								<li>Career</li>
							</a>
							<a href="/dashboard">
								<li>Client Portal</li>
							</a>
							<a href="/signup">
								<li>Start Membership</li>
							</a>
							<a href="#">
								<li>Contact Us</li>
							</a>
						</ul>
					</div>
					<div className={styles.services}>
						<h4>Services</h4>
						<ul>
							<a href="#">
								<li>Real Estate</li>
							</a>
							<a href="#">
								<li>Retirement</li>
							</a>
							<a href="#">
								<li>Cryptocurrency</li>
							</a>
							<a href="#">
								<li>NFTs</li>
							</a>
							<a href="#">
								<li>Stocks & ETFs</li>
							</a>
							<a href="#">
								<li>Foreign Exchange</li>
							</a>
						</ul>
					</div>
					<div className={styles.legal}>
						<h4>Legal</h4>
						<ul>
							<a href="#">
								<li>Risk Disclosure</li>
							</a>
							<a href="#">
								<li>Anti Spam Policy</li>
							</a>
							<a href="#">
								<li>Anti Money Launderi</li>
							</a>
							<a href="#">
								<li>Placement of Statemen</li>
							</a>
							<a href="/privacy">
								<li>Privacy Policy</li>
							</a>
							<a href="/terms">
								<li>Terms of Use</li>
							</a>
						</ul>
					</div>
				</div>
				<div className={styles.mid}></div>
				<div className={styles.foot}>
					<p>
						How {frontendInfo?.site_name} calculates return on Investment, all return figures shown above are actual and fixed, not for illustrative purposes only.
						Before investing, consider your investment objectives and {frontendInfo?.site_name}'s charges and expenses. {frontendInfo?.site_name}'s internet-based services are
						designed to assist clients in achieving discrete financial goals. They are intended to provide comprehensive tax advice and financial planning
						with respect to every aspect of a client's financial situation and can incorporate specific investments that clients hold elsewhere. Quantum
						Finance is available to everyone Globally
					</p>
					<div>
						<p>Copyright © 2020 {frontendInfo?.site_name}, All rights reserved.</p>
						<ul>
							<a href="#">
								<li>Certificate of Incorporation</li>
							</a>
							<a href="/terms">
								<li>Term of Use</li>
							</a>
							<a href="/privacy">
								<li>Privacy Policy</li>
							</a>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Footer;
