import React, { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

import styles from "./Input.module.css";

const Input = ({ label, type, name, placeholder, onChange, value }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <div className={styles.inputContainer}>
      <label htmlFor={name} className={`${styles.label}`}>
        {label} <span className={styles.required}>*</span>
      </label>
      <div className={styles.inputWrapper}>
        <input
          required
          type={passwordVisible ? "text" : type}
          id={name}
          name={name}
          value={value}
          className={`${styles.input} ${styles.text}`}
          placeholder={placeholder}
          onChange={onChange}
        />
        {type === "password" &&
          (passwordVisible ? (
            <FaRegEye
              className={styles.icon}
              onClick={() => setPasswordVisible((prev) => !prev)}
            />
          ) : (
            <FaRegEyeSlash
              className={styles.icon}
              onClick={() => setPasswordVisible((prev) => !prev)}
            />
          ))}
      </div>
    </div>
  );
};

export default Input;
