import React from "react";
import styles from "./styles.module.css";
import SinglePlan from "./SinglePlan";
import {
  cardBg1,
  cardBg2,
  cardBg3,
  cardBg4,
  cardBg5,
  cardBg6,
} from "../../../images/image";
import Cookies from "js-cookie";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
const AllPlan = ({
  setTransferFunds,
  openModalWithPlanDetails,
  //for styling
  light,
}) => {
  let api = "https://broker-backend-service.bigrouting.io";
  //  api = "http://localhost:8000"
  let user_id = Cookies.get("auth-token");
  const [plans, setPlans] = useState([]);
  console.log(plans);
  async function getPlans() {
    let { data, status } = await axios.get(api + "/plans", {
      headers: {
        "Content-Type": "application/json",
        "x-tenant-id": localStorage.getItem("tenant_id"),
        Authorization: "Bearer " + user_id,
      },
    });

    // if (status == 401 ){

    //   toast.error("Session expired, please login again");
    //   setTimeout(() => {
    //     toast.dismiss();
    //   }, 3000);
    //   localStorage.removeItem("token");
    //   localStorage.removeItem("auth-token");
    //   window.location.href = "/login";
    // }

    let plansWithoutinsuranceinname = data.filter(
      (el) => !el.name.includes("Insurance")
    );
    console.log(data);
    setPlans(plansWithoutinsuranceinname);
  }

  useEffect(() => {
    getPlans();
  }, []);

  let backgrounds = [cardBg1, cardBg2, cardBg3, cardBg4, cardBg5, cardBg6];

  return (
    <div className={[styles.wrapper, light && styles.lightWrapper].join(" ")}>
      {plans?.map((el, i) => (
        <SinglePlan
          {...el}
          key={i}
          bg={backgrounds[i]}
          setTransferFunds={setTransferFunds}
          openModalWithPlanDetails={openModalWithPlanDetails}
          // for styleing
          light={light}
        />
      ))}
    </div>
  );
};

export default AllPlan;
