import React, { useState, useEffect } from "react";
import styles from "./Header.module.css";

const Header2 = ({ frontendInfo }) => {
	const [activePage, setActivePage] = useState("Home");
	const [scrolled, setScrolled] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [submenuOpen, setSubmenuOpen] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 120) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div className={`${styles.container} .home2 `} style={scrolled ? { backgroundColor: "var(--secondary_color)" } : {}}>
			<div className={styles.contentWrapper}>
				<div className={styles.left}>
					<a href="/">
						<img width={50} src={frontendInfo?.logo} alt="quantfinances logo" />
					</a>
				</div>
				<div className={styles.right}>
					<nav className={styles.navbar}>
						<ul>
							<a href="/" className={activePage === "Home" ? styles.active : ""}>
								<li>Home</li>
							</a>
							<a href="/company" className={activePage === "Company" ? styles.active : ""}>
								<li>Company</li>
							</a>
							<div className={activePage === "Services" ? styles.active : ""}>
								<li className={styles.servicesLi}>
									<span>Services</span>
									<img src="/home2/icons/down.svg" alt="down icon" />
									<ul className={styles.navSubmenu}>
										<div></div>
										<a href="/services/realstate">
											<li>Real State</li>
										</a>
										<a href="#">
											<li>Gold</li>
										</a>
										<a href="#">
											<li>Cryptocurrency</li>
										</a>
										<a href="#">
											<li>Retirement</li>
										</a>
										<a href="#">
											<li>NFT</li>
										</a>
										<a href="#">
											<li>Foreign Exchange</li>
										</a>
										<a href="#">
											<li>Stock & ETFs</li>
										</a>
										<a href="#">
											<li>Foreign Exchange</li>
										</a>
									</ul>
								</li>
							</div>

							<a href="/career" className={activePage === "Career" ? styles.active : ""}>
								<li>Career</li>
							</a>
							<a href="/contact" className={activePage === "Contact" ? styles.active : ""}>
								<li>Contact</li>
							</a>
						</ul>
					</nav>
					<a href="/dashboard">
						<button type="button" className={styles.portalBtn}>
							Client Portal <img src="/home2/icons/circle-user-solid.png" alt="user icon" />
						</button>
					</a>
					<button onClick={() => setMenuOpen((prev) => !prev)} type="button" className={styles.burgerMenue}>
						<img src="/home2/icons/burger.svg" alt="burger menu icon" />
					</button>
					<nav className={`${styles.burgerNavbar} ${menuOpen ? styles.burgerMenueOpened : ""}`}>
						<ul>
							<a href="/" className={activePage === "Home" ? styles.active : ""}>
								<li>Home</li>
							</a>
							<a href="company" className={activePage === "Company" ? styles.active : ""}>
								<li>Company</li>
							</a>
							<a href="#" className={activePage === "Services" ? styles.active : ""} onClick={() => setSubmenuOpen((prev) => !prev)}>
								<li>
									<span>Services</span> <img src="/home2/icons/down.svg" alt="down icon" />
								</li>
							</a>

							<ul className={`${styles.submenu} ${submenuOpen ? styles.servicesSubOpened : ""}`}>
								<a href="/services/realstate">
									<li>Real State</li>
								</a>
								<a href="#">
									<li>Gold</li>
								</a>
								<a href="#">
									<li>Cryptocurrency</li>
								</a>
								<a href="#">
									<li>Retirement</li>
								</a>
								<a href="#">
									<li>NFT</li>
								</a>
								<a href="#">
									<li>Foreign Exchange</li>
								</a>
								<a href="#">
									<li>Stock & ETFs</li>
								</a>
								<a href="#">
									<li>Foreign Exchange</li>
								</a>
							</ul>
							<a href="career" className={activePage === "Career" ? styles.active : ""}>
								<li>Career</li>
							</a>
							<a href="contact" className={activePage === "Contact" ? styles.active : ""}>
								<li>Contact</li>
							</a>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	);
};

export default Header2;
