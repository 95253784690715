import React, { useState } from "react";

import Input from "../Input/Input";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
const EnterNewPassword = () => {
  const navigate = useNavigate("/");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const submitHandler = async () => {
    let email = localStorage.getItem("email");
    let code = localStorage.getItem("code");

    let api = "https://broker-backend-service.bigrouting.io";
    let route = "/auth/reset-password";

    let body = {
      email: email,
      token: code,
      password: newPassword,
    };

    const { data, status } = await axios.post(api + route, body, {});

    if (status === 200) {
      toast.success("Password Reset Successfully");
      console.log(data);
    } else {
      toast.error("Something went wrong");
    }

    toast.success("Redirecting to Login Page");
    setTimeout(() => {
      navigate("/login ");
    }, 4000);


  };
  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <h2 className={styles.title}>Reset Password</h2>
        </div>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={styles.form}
        >
          <Input
            label="New Password"
            type="password"
            name="password"
            placeholder="Enter Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Input
            label="Confirm New Password"
            type="password"
            name="confirmpassword"
            placeholder="Enter Password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
          <button
            type="submit"
            className={styles.button}
            onClick={submitHandler}
          >
            Save
          </button>
        </form>
      </div>

      <div className={styles.overlay}></div>
    </>
  );
};

export default EnterNewPassword;
