import React from "react";
import Hero from "../../Component/Home2/Hero/Hero";
import LetsTalk from "../../Component/Home2/ContactPage/LetsTalk";
import ContactUs from "../../Component/Home2/Sections/ContactUs";
import Header2 from "../../Component/Home2/Header/Header";
import Footer2 from "../../Component/Home2/Footer/Footer";

const Contact = ({ frontendInfo }) => {
	return (
		<div className="home2">
			<Header2 frontendInfo={frontendInfo} />
			<Hero
				videoLink="/home2/heros/contactVid.mp4"
				title="Contact Us"
				text="Do you have any queries or suggestions? Please contact us about all enquiries including membership and volunteer work using the form below."
				height="590px"
			/>
			<LetsTalk frontendInfo={frontendInfo} />

			<div>
				<iframe
					title="contact address"
					style={{ width: "100%", height: "500px" }}
					frameborder="0"
					marginheight="0"
					marginwidth="0"
					src="https://maps.google.com/maps?width=617&amp;height=321&amp;hl=en&amp;q=Hanham,%20Bristol,%20UK%20+(Hanham,%20Bristol,%20UK)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
				></iframe>
			</div>
			<ContactUs frontendInfo={frontendInfo} />
			<Footer2 frontendInfo={frontendInfo} />
		</div>
	);
};

export default Contact;
