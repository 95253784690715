import React, { useEffect, useState } from "react";
import styles from "./Actions.module.css";
import SingleAction from "./SingleAction";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import axios from "axios";


const Actions = ({ investData = { investData } }) => {
  const [earnings, setEarnings] = useState(0);
  async function get_earnings() {
    let api = "https://broker-backend-service.bigrouting.io";
    let user_id = Cookies.get("auth-token");
    try {
      let { data, status } = await axios.post(api + "/get_earnings", {
        position_id: investData.id,
      }, {
        headers: {
          "Content-Type": "application/json",
          "x-tenant-id": localStorage.getItem("tenant_id"),
          // Authorization: "Bearer " + user_id,
        },
      });

      if (status != 200) {
        return;
      }


      console.log(data)
      setEarnings(data);
      // setPositions(data);

    } catch (error) {
      console.log(error);
      // if (error.response.status === 401) {
      //   Cookies.remove("auth-token");
      //   toast.error("Session expired, please login again");
      //   // window.location.href = "/login";
      // }
    }
  }

  useEffect(() => {
    get_earnings()
  }, [investData]);

  let withdrawProfitHandler = async () => {
    let api = "https://broker-backend-service.bigrouting.io";
    let user_id = Cookies.get("auth-token");
    let url = api + "/positions/withdraw_earnings";
    // + investData.id;
    let body = {
      position_id: investData.id,
    }
    try {
      let { data, status } = await axios.post(url, body, {
        headers: {
          "x-tenant-id": localStorage.getItem("tenant_id"),
          Authorization: "Bearer " + user_id,
        },
      });

      if (status === 200) {
        toast.success(data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error("Something went wrong")
      }
    } catch (e) {
      toast.error("Something went wrong")
    }
  }

  let withdrawCapitalHandler = async () => {
    let api = "https://broker-backend-service.bigrouting.io";
    let user_id = Cookies.get("auth-token");
    let url = api + "/positions/withdraw_capital";
    // + investData.id;
    let body = {
      position_id: investData.id,
    }
    try {
      let { data, status } = await axios.post(url, body, {
        headers: {
          "x-tenant-id": localStorage.getItem("tenant_id"),
          Authorization: "Bearer " + user_id,
        },
      });

      if (status === 200) {
        toast.success(data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error("Something went wrong")
      }
    } catch (e) {
      toast.error("Something went wrong")
    }
  }



  const actionData = [
    {
      title: "Capital",
      value: investData.capital / Math.pow(10, 8),
      inUsd: investData.capital / Math.pow(10, 8),
      buttonText: "Withdraw Capital".toUpperCase(),
      handler: withdrawCapitalHandler
    },
    {
      title: "Accumulated Profits",
      value: earnings,
      inUsd: earnings,
      buttonText: "Claim rewards".toUpperCase(),
      handler: withdrawProfitHandler
    },
  ];
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Actions</h3>
      <div className={styles.boxWrapper}>
        {actionData.map((el, i) => (
          <SingleAction {...el} key={i} />
        ))}
      </div>
    </div>
  );
};

export default Actions;
