import React, { useEffect, useState } from "react";
import Hero from "../../Component/Home2/HomePage/Hero";
import SubHero from "../../Component/Home2/HomePage/SubHero";
import Purpose from "../../Component/Home2/HomePage/Purpose";
import WhyUs from "../../Component/Home2/HomePage/WhyUs";
import Overview from "../../Component/Home2/HomePage/Overview";
import Calltoaction from "../../Component/Home2/HomePage/Calltoaction";
import ContactUs from "../../Component/Home2/Sections/ContactUs";
import Testimonial from "../../Component/Home2/HomePage/Testimonial";
import Footer2 from "../../Component/Home2/Footer/Footer";
import Header2 from "../../Component/Home2/Header/Header";
import AllPlan from "../../Component/InvestmentPlan/AllPlans/AllPlan";
import styles from "./styles.module.css";
const Home2 = ({ frontendInfo }) => {
  const [transferFunds, setTransferFunds] = useState(false);
  const [planDetails, setPlanDetails] = useState({});
  const [isInsured, setIsInsured] = useState(false);
  async function openModalWithPlanDetails(details) {
    setTransferFunds(true);
    setPlanDetails(details);
    setIsInsured(details.insured);
    console.log(details, "details");

    window.location.href = "/dashboard";
  }

  useEffect(() => {
    if (1 == 1) {
      // Create and append the Tidio script
      const script = document.createElement('script');
      script.src = frontendInfo?.tidio_id;
      script.async = true;

      // Append script to the document head
      document.head.appendChild(script);

      // Cleanup script on component unmount
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [frontendInfo]);
  return (
    <div className="home2">
      <Header2 frontendInfo={frontendInfo} />{" "}
      <Hero frontendInfo={frontendInfo} />
      <SubHero frontendInfo={frontendInfo} />
      <div className={["homeWrapper", styles.wrapper].join(" ")}>
        <h2 className={styles.heading}>Plans</h2>
        <AllPlan
          setTransferFunds={setTransferFunds}
          openModalWithPlanDetails={openModalWithPlanDetails}
          light
        />
      </div>
      <Purpose frontendInfo={frontendInfo} />
      <WhyUs frontendInfo={frontendInfo} />
      <Overview frontendInfo={frontendInfo} />
      <Testimonial frontendInfo={frontendInfo} />
      <Calltoaction frontendInfo={frontendInfo} />
      <ContactUs frontendInfo={frontendInfo} />
      <Footer2 frontendInfo={frontendInfo} />
    </div>
  );
};

export default Home2;
