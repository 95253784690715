import React, { useState } from "react";
import styles from "./Profile.module.css";
import { toast } from "react-toastify";

let ref = "https://muliplymoney.ai" + "/register/" + localStorage.getItem("evm_wallet");

const Profile = ({ userInfo }) => {
  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.verificationContainer}>
        <p className={styles.verificationText}>
          Invite your friends to earn 5% of their trading fees.
        </p>
        <button
          onClick={() => {
            navigator.clipboard.writeText(ref);
            setTimeout(() => {
              toast.success("Copied to clipboard");
            }, 300);
          }}
          className={styles.button}>Copy Referral Link</button>
      </div> */}

      <div>
        <h5 className={styles.title}>Personal Information</h5>
        <p className={styles.text}>
          Basic info, like your name and address, that you use on our platform.
        </p>
      </div>
      <div className={styles.informationContainer}>
        {/* <div className={styles.info}>
          <p className={styles.infoText}>Full Name</p>
          <p className={styles.infoText}>{userInfo?.first_name} {userInfo?.last_name}</p>
        </div> */}

        <div className={styles.info}>
          <p className={styles.infoText}>User Name</p>
          <p className={styles.infoText}>{userInfo?.email}</p>
        </div>
        <div className={styles.info}>
          <p className={styles.infoText}>Email</p>
          <p className={styles.infoText}>{userInfo?.email}</p>
        </div>{" "}
        {/* <div className={styles.info}>
          <p className={styles.infoText}>Phone Number</p>
          <p className={styles.infoText}>{userInfo?.phone ? userInfo.phone : "Not Set"}</p>
        </div>{" "}
        <div className={styles.info}>
          <p className={styles.infoText}>Address</p>
          <p className={styles.infoText}>{userInfo?.address ? userInfo.address : "Not Set"}</p>
        </div>{" "}
        <div className={styles.info}>
          <p className={styles.infoText}>City</p>
          <p className={styles.infoText}>{userInfo?.city ? userInfo.city : "Not Set"}</p>
        </div>
        <div className={styles.info}>
          <p className={styles.infoText}>State</p>
          <p className={styles.infoText}>{userInfo?.state ? userInfo.state : "Not Set"}</p>
        </div>{" "}
        <div className={styles.info}>
          <p className={styles.infoText}>Country </p>
          <p className={styles.infoText}>{userInfo?.country ? userInfo.country : "Not Set"}</p>
        </div>{" "}
        <div className={styles.info}>
          <p className={styles.infoText}>Nationality</p>
          <p className={styles.infoText}>{userInfo?.address ? userInfo.address : "Not Set"}</p>
        </div>{" "} */}
        {/* <div className={styles.info}>
          <p className={styles.infoText}>BEP20 Deposit Address</p>
          <p className={styles.infoText}>{localStorage.getItem("evm_wallet")}</p>
        </div> */}
        <div className={styles.info}>
          <p className={styles.infoText}>Account Status</p>
          <p className={styles.infoText}>{userInfo?.status ? userInfo.status : "Active"}</p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
