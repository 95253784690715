import React, { useState } from "react";
import formatNumber from "../../utils";

import Toggle from "./Toggle/Toggle";
import styles from "./styles.module.css";

const SinglePlan = ({
  openModalWithPlanDetails,
  name,
  id,
  minimum_amount,
  maximum_amount,
  interest_schedule,
  interest_rate,
  duration,
  duration_in,
  refund_capital,
  auto_prevent_capital_withdrawal,
  status,

  //for styling
  light,
}) => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={[styles.cardWrapper, light && styles.lightcardWrapper].join(
        " "
      )}
    >
      <div
        className={styles.card}
      // style={{ backgroundImage: `url(${bg})` }}
      >
        <h4 className={styles.name}>{name}</h4>
        <div className={styles.box}>
          <div className={styles.topBox}>
            <div className={styles.interestBox}>
              <div className={styles.amountBox}>
                <h5 className={styles.amount}>{interest_rate}%</h5>
                <p className={styles.tagline}>{interest_schedule} Interest</p>
              </div>
            </div>
            <div className={styles.weekBox}>
              <div className={styles.amountBox}>
                <h5 className={styles.amount}>{duration}</h5>
                <p className={styles.tagline}>{duration_in}</p>
              </div>
            </div>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.info}>
              <p className={styles.text}>Minimum Deposit</p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                <span className={styles.highlight}>
                  {minimum_amount / Math.pow(10, 8)}
                </span>{" "}
                <span className={styles.currency}>usd</span>
              </p>
            </div>
            <div className={styles.info}>
              <p className={styles.text}>Maximum Deposit</p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                <span className={styles.highlight}>
                  {maximum_amount < 1
                    ? "Unlimited"
                    : maximum_amount / Math.pow(10, 8)}
                </span>{" "}
                <span className={styles.currency}>
                  {maximum_amount < 1 ? "" : "usd"}
                </span>
              </p>
            </div>
            <div className={styles.info}>
              <p className={styles.text}>Duration</p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                <span className={styles.highlight}>
                  {duration} 
                </span>{" "}
                <span className={styles.currency}>
                {duration_in}
                </span>
              </p>
            </div>
            <div className={styles.info}>
              <p className={styles.text}>Capital Return </p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                {refund_capital ? "YES" : "NO"}
              </p>
            </div>
            <div className={styles.info}>
              <p className={styles.text}>Status</p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                <span className={styles.highlight}>{status}</span>
              </p>
            </div>
            {/* <div className={styles.info}>
              <p className={styles.text}>Insurance </p>
              <span className={styles.seperator}>-</span>
              <Toggle
                id={`status ${id}`}
                enable={active}
                setEnable={
                  (e) => {
                    setActive(e)
                    localStorage.setItem("enabled-insurance", true);
                  }
                }
                color={bg}

              />
            </div> */}
          </div>
        </div>
      </div>{" "}
      <button
        onClick={() => {
          openModalWithPlanDetails({
            name,
            id,
            // description,
            minimum_amount,
            maximum_amount,
            // possible_durations,
            // possible_interest_rates,
            // possible_interest_rates_for_insurance,
            // interest_rate,
            // first_duration,
            // interest_schedule,
            // status,
            // background,
            // refund_capital,
            // bg,
            // insured,
          });
          // setTransferFunds(true)
        }}
        className={styles.button}
      >
        Invest
      </button>
    </div>
  );
};

export default SinglePlan;
