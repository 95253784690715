import React, { useState } from "react";

import EnterEmail from "./EnterEmail";
import EnterOtp from "./EnterOtp";
import EnterNewPassword from "./EnterNewPassword";

const ForgotPassword = () => {
  const [showEnterOtopComponent, setShowEnterOtpComponent] = useState(false);
  const [showResetPasswordComponent, setShowResetPasswordComponent] =
    useState(false);
  return (
    <>
      {!showEnterOtopComponent && (
        <EnterEmail setShowEnterOtpComponent={setShowEnterOtpComponent} />
      )}
      {showEnterOtopComponent && !showResetPasswordComponent && (
        <EnterOtp
          setShowResetPasswordComponent={setShowResetPasswordComponent}
        />
      )}
      {showResetPasswordComponent && <EnterNewPassword />}
    </>
  );
};

export default ForgotPassword;
