import { useEffect } from "react";
import { useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Header from "./Component/Header/Header";
import OurPlans from "./pages/OurPlans/OurPlans";
import LeaderBoard from "./pages/LeaderBoardPage/LeaderBoardPage";
import InvestmentHistoryPage from "./pages/InvestmentHistoryPage/InvestmentHistoryPage";
import { DepositHistoryPage } from "./pages/InvestmentHistoryPage/InvestmentHistoryPage";
import MyStakes from "./pages/MyStakes/PlanDetailsPage";
import Login from "./pages/LoginSignUp/Login";
import SignUp from "./pages/LoginSignUp/SignUp";
import MyProfile from "./pages/MyProfile/MyProfile";
import MakeYourPayment from "./pages/MakeYourPayment/MakeYourPayment";
import InvestmentPlan from "./pages/InvestmentPlan/InvestmentPlan";
import Portfolio from "./pages/Portfolio/Portfolio";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import axios from "axios";
import Footer from "./Component/Footer/Footer";
import Home1 from "./pages/Home1/Home1";
import Contact from "./pages/Home2/Contact";
import Company from "./pages/Home2/Company";
import Career from "./pages/Home2/Career";
import Realstate from "./pages/Home2/Realstate";
import Home2 from "./pages/Home2/Home2";
import Header2 from "./Component/Home2/Header/Header";
import Footer2 from "./Component/Home2/Footer/Footer";
import Terms from "./pages/Home2/Terms";
import Privacy from "./pages/Home2/Privacy";
import ForgotPassword from "./pages/LoginSignUp/ForgotPassword";
import { fetchFrontendInfo } from "./redux/frontend";
import { useDispatch, useSelector } from "react-redux";

function App() {
  let frontendInfo = useSelector((state) => state.frontend.data);

  console.log(frontendInfo)
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("App.js mounted");
    dispatch(fetchFrontendInfo());
  }, []);
  let api = "https://broker-backend-service.bigrouting.io";
  let user_id = Cookies.get("auth-token");
  const [plans, setPlans] = useState([]);

  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    document.title = frontendInfo?.site_name || "Dashboard";
  }, []);

  let getUserInfo = async () => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/signup" ||
      window.location.pathname === "/login" ||
      window.location.pathname === "/company" ||
      window.location.pathname === "/career" ||
      window.location.pathname === "/contact" ||
      window.location.pathname === "/forgotCode" ||
      window.location.pathname === "/forgotCode"

    )
      return;

    let token = Cookies.get("auth-token");
    console.log(token, "token");
    if (!token) {
      if (window.location.pathname != "/login") {
        toast.error("Session expired, please login again");
        // window.location.href = "/login";
      }
      return;
    }

    try {
      let { data, status } = await axios.get(api + "/data", {
        headers: {
          "Content-Type": "application/json",
          "x-tenant-id": localStorage.getItem("tenant_id"),
          Authorization: "Bearer " + user_id,
        },
      });
      let balance = data?.balance / Math.pow(10, 8);
      setUserInfo({ ...data.data, balance });
      console.log(data.data);
    } catch (e) {
      console.log(e);
      if (e.response.status == 401) {
        Cookies.remove("auth-token");
        toast.error("Session expired, please login again");
        // window.location.href = "/login";
      }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, [user_id]);

  const Register = async (e, values, ref) => {
    e.preventDefault();
    let url = "https://broker-backend-service.bigrouting.io";
    let body = {
      email: values.email.toLowerCase(),
      password: values.password,
      first_name: values.firstname,
      last_name: values.lastname,
      user_name: values.username,
      password: values.password,
      security_pin: values.passcode,
      affiliate_wallet: values.affiliate_wallet,
      phone: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    };

    let { data, status } = await axios.post(url + "/register", body, {
      headers: {
        "Content-Type": "application/json",
        "x-tenant-id": localStorage.getItem("tenant_id"),
      },
    });

    if (status == 200) {
      toast.info("User created successfully")
      // toast.success("User created successfully");
      toast.loading("Redirecting to login");
      setTimeout(() => {
        toast.dismiss();
        // window.location.href = "/login";
        navigate("/login");
      }, 4000);
    } else {
      console.log(data);
      toast.error(data.message);
    }

    // window.location.href = "/login";
    // window.open("/login", "_self");

  };

  const navigate = useNavigate();
  const location = useLocation();
  const hideComponent =
    location.pathname === "/" ||
    location.pathname === "/signup" ||
    location.pathname.startsWith("/services") ||
    location.pathname.startsWith("/career") ||
    location.pathname.startsWith("/company") ||
    location.pathname.startsWith("/contact") ||
    location.pathname.startsWith("/privacy") ||
    location.pathname.startsWith("/terms");
  const hideHeader =
    location.pathname === "/" ||
    location.pathname.startsWith("/services") ||
    location.pathname.startsWith("/career") ||
    location.pathname.startsWith("/company") ||
    location.pathname.startsWith("/contact") ||
    location.pathname.startsWith("/privacy") ||
    location.pathname.startsWith("/terms");
  // useEffect(() => {
  //   console.log(user_id, "user_id in useeffect");
  //   if (user_id === "undefined" || !user_id) {
  //     navigate("/login");
  //   }
  // }, []);
  return (
    <>
      {!hideHeader && <Header frontendInfo={frontendInfo} userInfo={userInfo} />}
      <ToastContainer />

      {!hideComponent && (
        <div className="container home1">
          <ToastContainer />{" "}
          <div className="mainContainer">
            <div className="mainWrapper">
              {" "}
              <Routes>
                <Route
                  path="/dashboard"
                  element={<OurPlans userInfo={userInfo} frontendInfo={frontendInfo} />}
                />{" "}
                <Route path="/leaderboard" element={<LeaderBoard frontendInfo={frontendInfo} />} />
                <Route path="/investments/:id" element={<MyStakes frontendInfo={frontendInfo} />}></Route>
                <Route
                  path="/history"
                  element={<InvestmentHistoryPage frontendInfo={frontendInfo} />}
                ></Route>
                <Route
                  path="/deposit-history"
                  element={<DepositHistoryPage frontendInfo={frontendInfo} />}
                ></Route>
                <Route
                  path="/profile"
                  element={<MyProfile userInfo={userInfo} frontendInfo={frontendInfo} />}
                ></Route>
                <Route path="/deposit" element={<MakeYourPayment frontendInfo={frontendInfo} />}></Route>
                <Route
                  path="/plans"
                  element={
                    <InvestmentPlan
                      userInfo={userInfo}
                      getUserInfo={getUserInfo}
                      frontendInfo={frontendInfo}
                    />
                  }
                ></Route>
                <Route
                  path="/portfolio"
                  element={<Portfolio userInfo={userInfo} frontendInfo={frontendInfo} />}
                ></Route>
              </Routes>
            </div>
          </div>
          <Footer frontendInfo={frontendInfo} />
        </div>
      )}
      <Routes>
        <Route path="/login" element={<Login frontendInfo={frontendInfo} />} />
        <Route path="/forgotCode" element={<ForgotPassword frontendInfo={frontendInfo} />} />
        <Route path="/signup" element={<SignUp Register={Register} frontendInfo={frontendInfo} />} />
        <Route path="/signup/:ref" element={<SignUp Register={Register} frontendInfo={frontendInfo} />} />
        {/*  <Route path="/" element={<Home1 />} /> */}
      </Routes>

      {hideComponent && (
        <>
          <Routes>
            <Route path="/terms" element={<Terms frontendInfo={frontendInfo} />} />
            <Route path="/privacy" element={<Privacy frontendInfo={frontendInfo} />} />
            <Route path="/contact" element={<Contact frontendInfo={frontendInfo} />} />
            <Route path="/company" element={<Company frontendInfo={frontendInfo} />} />
            <Route path="/career" element={<Career frontendInfo={frontendInfo} />} />
            <Route path="/services/realstate" element={<Realstate frontendInfo={frontendInfo} />} />
            <Route path="/" element={<Home2 frontendInfo={frontendInfo} />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
