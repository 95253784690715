import React, { useEffect } from "react";
import classes from "./Markets.module.css";
const CoinGeckoWidget = () => {
  useEffect(() => {
    // Create the script element
    const script = document.createElement("script");
    script.src =
      "https://widgets.coingecko.com/gecko-coin-market-ticker-list-widget.js";
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <gecko-coin-market-ticker-list-widget
        locale="en"
        outlined="true"
        initial-currency="usd"
        dark-mode="true"
      ></gecko-coin-market-ticker-list-widget>
    </div>
  );
};

export default CoinGeckoWidget;
