import React from "react";
import styles from "./ActivePlan.module.css";
import { plan1, plan2, plan3 } from "../../../images/image";
import SinglePlan from "./SinglePlan/SinglePlan";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";

const ActivePlan = ({ frontendInfo }) => {
  let api = "https://broker-backend-service.bigrouting.io";
  let user_id = Cookies.get("auth-token");
  const [plans, setPlans] = useState([]);

  async function getActivePlan() {
    try {
      let { data, status } = await axios.get(api + "/positions", {
        headers: {
          "Content-Type": "application/json",
          "x-tenant-id": localStorage.getItem("tenant_id"),
          Authorization: "Bearer " + user_id,
        },
      });

      let { data: plans } = await axios.get(api + "/plans", {
        headers: {
          "Content-Type": "application/json",
          "x-tenant-id": localStorage.getItem("tenant_id"),
          Authorization: "Bearer " + user_id,
        }
      });




      // if (status == 401 ){

      //   toast.error("Session expired, please login again");
      //   setTimeout(() => {
      //     toast.dismiss();
      //   }, 3000);
      //   localStorage.removeItem("token");
      //   localStorage.removeItem("auth-token");
      //   window.location.href = "/login";
      // }
      console.log(data);
      let plans_array = [];

      for (let i = 0; i < data.length; i++) {
        let plan = data[i];

        let plan_data = plans.find((el) => el.id == plan.plan_id);
        let plan_obj = {
          image: plan1,
          id: plan.id,
          investedAmount: plan.capital / Math.pow(10, 8),
          startDate: new Date(plan.created_at_timestamp * 1000).toLocaleString(),
          endDate: new Date(plan.end_date_timestamp * 1000).toLocaleString(),
          totalReturn: plan_data?.interest_rate,
          schedule: plan_data?.interest_schedule,
        };
        plans_array.push(plan_obj);
      }
      setPlans(plans_array);
    } catch (e) {
      console.log(e);
      if (e.response.status == 401) {
        Cookies.remove("auth-token");
        toast.error("Session expired, please login again");
        // window.location.href = "/login";
      }
    }
  }

  useEffect(() => {
    getActivePlan();
  }
    , []);


  return (
    <section className={styles.wrapper}>
      <h5 className={styles.title}>Active Investments ({plans.length})</h5>
      <div className={styles.allPlan}>
        {plans.map((el, i) => (
          <SinglePlan key={i} {...el} />
        ))}
      </div>
    </section>
  );
};

export default ActivePlan;
