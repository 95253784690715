import React from "react";
import styles from "./Heading.module.css";

const Heading = () => {
  return (
    <div className={styles.header}>
      <div>
        <h3 className={styles.heading}>Currencies</h3>
        <p className={styles.text}>
          {/* You have full control to manage your own account settings */}
        </p>
      </div>
    </div>
  );
};

export default Heading;
