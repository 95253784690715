import React, { useState } from "react";
import Input from "../Input/Input";
import styles from "./styles.module.css";

const EnterOtp = ({ setShowResetPasswordComponent }) => {
  const [code, setCode] = useState("");

  const submitHandler = () => {
    localStorage.setItem("code", code);
    setShowResetPasswordComponent(true);
  };
  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <h2 className={styles.title}>Enter the Code</h2>
          <p className={styles.text}>Please check your registerd email</p>
        </div>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={styles.form}
        >
          <Input
            label="Enter Code"
            type="text"
            name="text"
            placeholder="Enter the Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <button
            type="submit"
            className={styles.button}
            onClick={submitHandler}
          >
            Next
          </button>
        </form>
      </div>

      <div className={styles.overlay}></div>
    </>
  );
};

export default EnterOtp;
