import React from "react";
import Hero from "../../Component/Home2/Hero/Hero";
import WorkWithUs from "../../Component/Home2/CareerPage/WorkWithUs";
import ContactUs from "../../Component/Home2/Sections/ContactUs";
import Header2 from "../../Component/Home2/Header/Header";
import Footer2 from "../../Component/Home2/Footer/Footer";

const Career = ({ frontendInfo }) => {
	return (
		<div className="home2">
			<Header2 frontendInfo={frontendInfo} />
			<Hero
				videoLink="/home2/heros/careerVid.mp4"
				title={frontendInfo?.site_name}
				text="Help us build the future by empowering everyday people to generate income and control their wealth"
				height="660px"
			/>
			<WorkWithUs frontendInfo={frontendInfo} />
			<ContactUs frontendInfo={frontendInfo} />
			<Footer2 frontendInfo={frontendInfo} />
		</div>
	);
};

export default Career;
